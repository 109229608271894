import React, { useState, useEffect } from "react";
import "./testimonials.css";
import { FaStar } from "react-icons/fa";

// import Swiper core and required modules
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../utils/firebase";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const colors = {
  orange: "#FFBA5A",
  grey: "#a9a9a9",
};

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const testimonialsCollection = collection(firestore, "Testimonials");
        const testimonialsSnapshot = await getDocs(testimonialsCollection);
        const testimonialsData = [];

        testimonialsSnapshot.forEach((doc) => {
          const testimonial = { id: doc.id, ...doc.data() };
          testimonialsData.push(testimonial);
        });

        setTestimonials(testimonialsData);
      } catch (error) {
        console.error("Error al obtener los testimonios:", error);
      }
    };

    fetchTestimonials();
  }, []);

  const renderStars = (rating) => {
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating - filledStars >= 0.5;

    return Array(5)
      .fill(0)
      .map((_, index) => {
        if (index < filledStars) {
          return <FaStar key={index} size={24} color={colors.orange} />;
        } else if (hasHalfStar && index === filledStars) {
          return <FaStar key={index} size={24} color={colors.orange} />;
        } else {
          return <FaStar key={index} size={24} color={colors.grey} />;
        }
      });
  };

  return (
    <section className="testimonials container section" id="testimonial">
      <h2 className="section__title">Clientes y reseñas</h2>

      <Swiper
        className="testimonials__container grid"
        modules={[Pagination]}
        spaceBetween={30}
        slidesPerView={1}
        loop={true}
        grabCursor={true}
        pagination={{ clickable: true }}
      >
        {testimonials.map((testimonial) => {
          const { name, occupation, review, rating } = testimonial;
          return (
            <SwiperSlide className="testimonial__item" key={testimonial.id}>
              <h3 className="testimonials__title">{name}</h3>
              <span className="subtitle">{occupation}</span>
              <div className="stars">{renderStars(rating)}</div>
              <div className="comment">{review}</div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;
