import React from "react";
import "./about.css";
import Image from "../../assets/avatar-2.svg";
import AboutBox from "./AboutBox";
import curriculum from "../../assets/Angelo-Pescetto-Curriculum.pdf";

const About = () => {
  return (
    <section className="about container section" id="about">
      <h2 className="section__title">Acerca de mi</h2>

      <div className="about__container grid">
        <img src={Image} alt="" className="about__img" />

        <div className="about__data grid">
          <div className="about__info">
            <p className="about__description">
              Soy Angelo Pescetto, un experimentado desarrollador web con sede
              en Uruguay. Mi trayectoria incluye una amplia experiencia en el
              diseño, construcción y personalización de sitios web, así como un
              sólido dominio de WordPress.
            </p>
            <a
              href={curriculum}
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              Descargar CV
            </a>
          </div>

          <div className="about__skills grid">
            <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">Development</h3>
                <span className="skills__number">90%</span>
              </div>

              <div className="skills__bar">
                <span className="skills__percentage development"></span>
              </div>
            </div>

            <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">UI/UX design</h3>
                <span className="skills__number">80%</span>
              </div>

              <div className="skills__bar">
                <span className="skills__percentage ui__design"></span>
              </div>
            </div>

            <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">Wordpress</h3>
                <span className="skills__number">90%</span>
              </div>

              <div className="skills__bar">
                <span className="skills__percentage photography"></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <AboutBox /> */}
    </section>
  );
};

export default About;
