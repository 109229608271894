import Work1 from "../../assets/work-1.svg";
import Work2 from "../../assets/work-2.svg";
import Work3 from "../../assets/work-3.svg";
import Work4 from "../../assets/work-4.svg";
import Work5 from "../../assets/work-5.svg";
import Work6 from "../../assets/work-6.svg";

// eslint-disable-next-line no-sparse-arrays
const Menu = [
  {
    id: 1,
    image: Work1,
    title: "Tienda Online de Ropa",
    category: "Ecommerce",
    link: "https://leggero.store/",
  },
  {
    id: 2,
    image: Work3,
    title: "Proyecto Colonia",
    category: "Publico",
    link: "https://proyectocolonia.website",
  },
  {
    id: 3,
    image: Work2,
    title: "Cronologia UY",
    category: "Personal",
    link: "https://cronologia-uy.vercel.app/",
  },
  // ,
  // {
  //   id: 4,
  //   image: Work4,
  //   title: "Lito Futbol Club",
  //   category: "Publico",
  //   link: "https://litofc.com",
  // },
  // ,
  // {
  //   id: 5,
  //   image: Work5,
  //   title: "iMac Mockup Design",
  //   category: "Creative",
  //   link: "",
  // },
  // ,
  // {
  //   id: 6,
  //   image: Work6,
  //   title: "Game Store App Concept",
  //   category: "Art",
  //   link: "",
  // },
];

export default Menu;
