const Data = [
  {
    id: 1,
    category: "education",
    icon: "icon-graduation",
    year: "2024 - Actual",
    title: "Ingeniero en Computación",
    desc: "Actualmente estudio remotamente Ingenieria en la Universidad de la República (Udelar)",
  },
  {
    id: 2,
    category: "education",
    icon: "icon-graduation",
    year: "2020 - 2023",
    title: "Analista Programador",
    desc: "Completé mis estudios de Analista Programador en el Instituto CTC Colonia (ORT).",
  },
  {
    id: 3,
    category: "education",
    icon: "icon-graduation",
    year: "2019 - 2021",
    title: "Bachillerato",
    desc: "En 2021, finalicé mi educación secundaria con un enfoque en el Bachillerato Científico.",
  },
  {
    id: 4,
    category: "education",
    icon: "icon-graduation",
    year: "2020",
    title: "Desarrollador Front-End",
    desc: "En 2020, decidí dar un paso importante en mi desarrollo profesional al formalizar mi aprendizaje con la realización de mi primer curso. Antes de eso, mi educación se basaba en el estudio autodidacta.",
  },
  {
    id: 5,
    category: "experience",
    icon: "icon-briefcase",
    year: "2021 - Actual",
    title: "Desarrollador Freelance",
    desc: "Como desarrollador freelance, ofrezco soluciones tecnológicas personalizadas utilizando diversos lenguajes de programación y herramientas. Mi enfoque se basa en comprender las necesidades del cliente y entregar resultados de alta calidad dentro de los plazos establecidos. Soy autónomo, comunicativo y adaptable a los cambios en el proyecto.",
  },
  {
    id: 6,
    category: "experience",
    icon: "icon-briefcase",
    year: "2021 - Actual",
    title: "Informático",
    desc: "Brindo servicios de soluciones en software y hardware, que incluyen reparaciones, actualizaciones, instalaciones, gestión de redes y resolución de problemas técnicos. Mi enfoque está en proporcionar soluciones eficientes y confiables a mis clientes, manteniéndome al día con las últimas tecnologías y tendencias del mercado.",
  },
];

export default Data;
