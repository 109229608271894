import React, { useState } from "react";
import "./addTestimonials.css";
import { FaStar } from "react-icons/fa";
import { collection, addDoc } from "firebase/firestore";
import { firestore } from "../../utils/firebase";

const colors = {
  orange: "#FFBA5A",
  grey: "#a9a9a9",
};

const AddTestimonial = () => {
  const [formData, setFormData] = useState({
    name: "",
    occupation: "",
    review: "",
  });

  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const stars = Array(5).fill(0);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClick = (value) => {
    setCurrentValue(value);
  };

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Validar campos requeridos
    if (!formData.name || !formData.review || !currentValue) {
      alert("Por favor, complete todos los campos obligatorios");
      return;
    }

    // Crear un objeto con los datos del testimonio
    const testimonialData = {
      name: formData.name,
      occupation: formData.occupation,
      review: formData.review,
      rating: currentValue,
    };

    try {
      const testimonialsCollection = collection(firestore, "Testimonials");
      await addDoc(testimonialsCollection, testimonialData);

      // Limpiar el formulario después de agregar el testimonio
      setFormData({
        name: "",
        occupation: "",
        review: "",
      });
      setCurrentValue(0);
      setHoverValue(undefined);

      alert("Reseña agregada exitosamente");

      // Recargar la página después de un breve retraso (por ejemplo, 1 segundo)
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error al agregar la reseña:", error);
      alert("Error al agregar la reseña");
    }
  };

  return (
    <section className="contact container section" id="AddTestimonial">
      <h2 className="section__title">Agregar Reseña</h2>

      <div className="contact__container grid">
        <div className="contact__info">
          <h3 className="contact__title">
            Si te gusta mi trabajo hazmelo saber
          </h3>
          <p className="contact__details">Agrega una reseña 🙌🏻</p>
        </div>

        <form className="contact__form" onSubmit={handleFormSubmit}>
          <div className="contact__form-group">
            <div className="contact__form-div">
              <input
                type="text"
                className="contact__form-input"
                placeholder="Escribe tu nombre"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>

            <div className="contact__form-div">
              <input
                type="text"
                className="contact__form-input"
                placeholder="Ocupacion - opcional"
                name="occupation"
                value={formData.occupation}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="contact__form-div contact__form-area">
            <textarea
              name="review"
              cols="30"
              rows="10"
              className="contact__form-input"
              placeholder="Escribe tu reseña"
              value={formData.review}
              onChange={handleInputChange}
            ></textarea>
          </div>

          <div className="stars">
            {stars.map((_, index) => (
              <FaStar
                key={index}
                size={24}
                onClick={() => handleClick(index + 1)}
                onMouseOver={() => handleMouseOver(index + 1)}
                onMouseLeave={handleMouseLeave}
                color={
                  (hoverValue || currentValue) > index
                    ? colors.orange
                    : colors.grey
                }
                style={{
                  marginRight: 10,
                  cursor: "pointer",
                }}
              />
            ))}
          </div>

          <button type="submit" className="btn">
            Enviar
          </button>
        </form>
      </div>
    </section>
  );
};

export default AddTestimonial;
