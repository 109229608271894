import React from "react";

const HeaderSocials = () => {
  return (
    <div className="home__socials">
      <a
        href="https://www.instagram.com/angelo.pescetto/"
        className="home__social-link"
        target="_blank"
        rel="noreferrer"
      >
        <i class="fa-brands fa-instagram"></i>
      </a>
      <a
        href="https://www.linkedin.com/in/angelopescetto/"
        className="home__social-link"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fa-brands fa-linkedin"></i>
      </a>
      <a
        href="https://github.com/angelop47"
        className="home__social-link"
        target="_blank"
        rel="noreferrer"
      >
        <i class="fa-brands fa-github"></i>
      </a>
      {/* <a
        href="https://vsco.co/angelo-p47/gallery"
        className="home__social-link"
        target="_blank"
        rel="noreferrer"
      >
        <i class="fa-solid fa-camera"></i>
      </a> */}
      {/* <a
        href="https://www.pinterest.es/angelo_p47/"
        className="home__social-link"
        target="_blank"
        rel="noreferrer"
      >
        <i class="fa-brands fa-pinterest"></i>
      </a> */}
    </div>
  );
};

export default HeaderSocials;
