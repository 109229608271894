import React, { useState } from "react";
import { useForm } from "@formspree/react";
import "./contact.css";

const Contact = () => {
  const [state, handleSubmit] = useForm("mzbloqwb");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(e);

    // Limpia el formulario después de 5 segundos
    setTimeout(() => {
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    }, 5000);
  };

  return (
    <section className="contact container section" id="contact">
      <h2 className="section__title">Contáctame</h2>

      <div className="contact__container grid">
        <div className="contact__info">
          <h3 className="contact__title">Por cualquier duda que tengas</h3>
          <p className="contact__details">Envíame un correo electrónico. 👋</p>
        </div>

        <form onSubmit={handleFormSubmit} className="contact__form">
          <div className="contact__form-group">
            <div className="contact__form-div">
              <input
                type="text"
                className="contact__form-input"
                placeholder="Escribe tu nombre"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>

            <div className="contact__form-div">
              <input
                type="email"
                className="contact__form-input"
                placeholder="Escribe tu email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="contact__form-div">
            <input
              type="text"
              className="contact__form-input"
              placeholder="Asunto"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
            />
          </div>

          <div className="contact__form-div contact__form-area">
            <textarea
              name="message"
              cols="30"
              rows="10"
              className="contact__form-input"
              placeholder="Escribe tu mensaje"
              value={formData.message}
              onChange={handleInputChange}
            ></textarea>
          </div>

          <button type="submit" className="btn">
            Enviar
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
