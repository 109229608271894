// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC07Gg5dgjDl9wFlxmzVnLB4blabSLNs3o",
  authDomain: "angelopescetto-5933b.firebaseapp.com",
  projectId: "angelopescetto-5933b",
  storageBucket: "angelopescetto-5933b.appspot.com",
  messagingSenderId: "347709791974",
  appId: "1:347709791974:web:e2cb87298aa75cfc676527",
  measurementId: "G-L6VVRE2YMC",
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export { firestore }; // Asegúrate de exportar firestore
