import React, { useState } from "react";
import "./App.css";
import Sidebar from "./components/sidebar/Sidebar";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Services from "./components/services/Services";
import Resume from "./components/resume/Resume";
import Portfolio from "./components/portfolio/Portfolio";
import Testimonials from "./components/testimonials/Testimonials";
import AddTestimonial from "./components/addTestimonials/AddTestimonials";
import Contact from "./components/contact/Contact";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

const App = () => {
  const [showAddTestimonial, setShowAddTestimonial] = useState(false);

  const toggleAddTestimonial = () => {
    setShowAddTestimonial(!showAddTestimonial);
  };

  return (
    <>
      <Sidebar />
      <main className="main">
        <Home />
        <About />
        <Services />
        <Resume />
        <Portfolio />
        <Testimonials />
        {showAddTestimonial && <AddTestimonial />}
        <div className="centered-content">
          <button type="submit" className="btn" onClick={toggleAddTestimonial}>
            {showAddTestimonial ? (
              <>
                Esconder <FaArrowUp />
              </>
            ) : (
              <>
                Agregar reseña <FaArrowDown />
              </>
            )}
          </button>
        </div>
        <Contact />
      </main>
    </>
  );
};

export default App;
